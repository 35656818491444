/* Content container */
.ProseMirror {
  min-height: 200px;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid #ccc;
  border-top: 0;
  outline: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
}

.ProseMirror-Menu .is-active,
.ProseMirror a[href] {
  color: #06b085;
}

.ProseMirror a[href] {
  font-weight: bold;
  text-decoration: underline;
}

.ProseMirror p {
  margin: 0;
  line-height: 1.25;
}

.ProseMirror > * + * {
  margin-top: 1em !important;
}

.ProseMirror ul,
.ProseMirror ol {
  margin-bottom: 0;
  padding: 0 1rem;
}

.ProseMirror li,
.ProseMirror li > p {
  margin: 0;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
  margin: 0 0 0.75em;
}
.ProseMirror h1 {
  font-size: 32px;
}
.ProseMirror h2 {
  font-size: 24px;
}
.ProseMirror h3 {
  font-size: 20px;
}
.ProseMirror h4 {
  font-size: 17px;
}
.ProseMirror h5 {
  font-size: 15px;
}
.ProseMirror h6 {
  font-size: 13px;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(#0d0d0d, 0.1);
  margin: 2rem 0;
}

.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

.ProseMirror td,
.ProseMirror th {
  border: 2px solid #ced4da;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}

.ProseMirror th {
  background-color: #f1f3f5;
  font-weight: bold;
  text-align: left;
}

.ProseMirror table .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.ProseMirror table p {
  margin: 0;
}
